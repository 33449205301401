import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Tilgung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Tilgung und Tilgungsplan" showCalc={false} />
            <Article>
                <p>
                    Als Tilgung wird jener Teil deiner monatlichen Kreditrate bezeichnet, der dir für die Rückzahlung
                    desKredits angerechnet wird. Damit du diesen genau im Blick hast und deine Finanzierung besser
                    planen kannst, empfiehlt es sich, einen Tilgungsplan zu erstellen. Zwar erhältst du einen solchen
                    Plan von deiner Bank, doch kannst du ihn mit wenigen Grundkenntnissen auch ganz einfach selbst
                    erstellen. Wir zeigen dir, wie du das angehst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was muss ich vor der Erstellung eines Tilgungsplans wissen?</h2>
                <p>
                    Als Kreditnehmer*in ist es für dich selbstverständlich essentiell, stets einen genauen Überblick
                    über deine monatliche Rate zu haben. Diese besteht aus den Kreditzinsen und der monatlichen
                    Rückzahlung (auch Tilgung). Wie hoch die tatsächliche Rückzahlungsrate dabei ausfällt, ist abhängig
                    von
                </p>
                <ul>
                    <li>
                        der Laufzeit deines{" "}
                        <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                            Kredits
                        </Link>
                    </li>
                    <li>dem Sollzinssatz</li>
                    <li>
                        von etwaigen Sondertilgungen – d.h. zusätzlichen Teilrückzahlungen, die du mit deiner Bank
                        vereinbarst und die dir dabei helfen können, die Tilgungsrate deines{" "}
                        <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                            Kredits
                        </Link>{" "}
                        zu beeinflussen.
                    </li>
                </ul>
                <p>Bevor du dich an den Tilgungsplan machst, solltest du diese Daten also in jedem Fall parat haben.</p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Was enthält einTilgungsplan?</h2>
                <p>
                    Um möglichst genaue Kenntnisse von deiner Gesamtbelastung sowie dem konkreten Verlauf der
                    Rückzahlung zu erhalten, ist ein Tilgungsplan erforderlich. Er enthält sämtliche für deinen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    relevanten Faktoren und gibt dir u.a. Aufschluss über
                </p>
                <ul>
                    <li>
                        <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                            Zinssatz
                        </Link>
                    </li>
                    <li>Laufzeit</li>
                    <li>
                        Zusätzliche Gebühren (Was du hier beachten musst, erklären wir dir übrigens in unserem Beitrag
                        zu den Kaufnebenkosten beim Immobilienerwerb)
                    </li>
                    <li>Zinsanteil</li>
                    <li>Tilgungsanteil</li>
                    <li>Gesamtbelastung</li>
                </ul>
                <p>
                    Du hast alle diese Parameter bei der Hand? Perfekt! – nun kannst du dich an die Erstellung deines
                    Tilgungsplans machen. Solltest du allerdings Unterstützung bei der Berechnung des Tilgungsanteils
                    benötigen, dann helfen wir dir im folgenden Abschnitt gerne weiter!
                </p>
                <hr />

                <h2>Tilgungsanteil berechnen – so wird’s gemacht</h2>
                <p>
                    Wenn du jenen Anteil deiner monatlichen Rate ermitteln willst, der dir für die Rückezahlung deines
                    Kredits gutgeschrieben wird, so musst du zunächst die Zinsen kennen.
                </p>

                <h3>Schritt 1: Zinsen berechnen</h3>
                <p>
                    Ohne allzuviele Erinnerung an die Schule wecken zu wollen, gehen wir jetzt gemeinsam ein
                    Rechenbeispiel durch, das dir zeigen soll, wie du die monatliche Tilgung schnell und unkompliziert
                    berechnest. Wir gehen dabei von einer{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditsumme
                    </Link>{" "}
                    von 100 000 Euro aus, die über eine Laufzeit von 5 Jahren mit 1% verzinst wird. (Wichtig: Der
                    Zinssatz sollte immer in Dezimalschreibweise angeführt werden – in unserem Fall also 0,01.)
                </p>
                <p>
                    <strong>Multipliziere zunächst das Kreditvolumen mit dem Zinssatz:</strong>
                </p>
                <p>
                    <strong>100 000 x 0,01 = 1000</strong>
                </p>
                <p>
                    Es ergibt sich also ein jährlicher Zinsaufwand von 1000 Euro. Das ist gut zu wissen, bei der
                    Berechnung des Tilgungsanteils der monatlichen Rate jedoch wenig hilfreich. Wir müssen also
                </p>
                <p>
                    <strong>die Gesamtsumme der jährlichen Zinsen durch 12 dividieren:</strong>
                </p>
                <p>
                    <strong>1000 : 12 = 83,33</strong>
                </p>
                <p>In unserem Beispiel sind folglich Zinsen in Höhe von 83,33 Euro pro Monat zu bezahlen.</p>
                <h3>Schritt 2: Annuität berechnen</h3>
                <p>
                    Um nun den Anteil deiner Kreditrate zu berechnen, der dir tatsächlich für dessen Rückzahlung
                    gutgeschrieben wird, benötigen wir noch die Annuität deines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    – jene Summe, die sich aus Zinsen und Tilgung zusammensetzt. Diese können wir mit folgender Formel
                    ermitteln:
                </p>
                <p>
                    <strong>Kreditbetrag x Annuitätenfaktor = Annuität</strong>
                </p>
                <p>
                    Setzen wir unsere Werte also in die Formel ein (die hochgestellte 5 bezieht sich auf die Laufzeit
                    des Kredites):
                </p>
                <p>
                    <strong>
                        100 000 × [(1,01<sup>5</sup> × 0,01) ÷ (1,01<sup>5</sup>– 1)] = 100 000 × 0,206 = 20 604
                    </strong>
                </p>
                <p>Es ergibt sich eine Annuität von 20 604 Euro pro Jahr, also 1 717 Euro pro Monat.</p>
                <h3>Schritt 3: Tilgunsanteil ermitteln</h3>
                <p>
                    Ziehst du von dieser die Zinsen ab, so erhältst du schließlich den Tilgungsanteil deiner monatlichen
                    Kreditrate:
                </p>
                <p>
                    <strong>1 717 - 83,33 = 1 633, 67</strong>
                </p>
                <p>
                    1 633, 67 Euro werden in diesem Fall also monatlich für die Rückerstattung des Kredits
                    gutgeschrieben.
                </p>
                <hr />

                <h2>Welche Sonderformen der Tilgung gibt es?</h2>
                <p>
                    Zusätzlich zu dem Anteil der monatlichen Rate, der dir für die Rückzahlung gutgeschrieben wird,
                    kannst du sogenannte <strong>Sondertilgungen</strong>
                    vornehmen. Dabei handelt es sich um außerordentliche Teilrückzahlungen. Je nach Kreditvertrag kannst
                    du mithilfe von Sondertilgungen entweder die Laufzeit der Finanzierung verkürzen oder die monatliche
                    Rate senken.
                </p>
                <p>
                    Wenn du über genügend Kapital verfügst, kannst du deinen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    selbstverständlich auch schon vor dem Ende der Laufzeit tilgen. Nach einer solchen{" "}
                    <strong>vorzeitigen Tilgung</strong> solltest du in jedem Fall alle Sicherheitsdokumente löschen
                    oder außer Evidenz nehmen lassen. Von der Bank erhältst du in Folge eine Löschungsquittung, mit der
                    du auch die Hypothek aus dem Grundbuch löschen kannst.
                </p>
                <hr />

                <h2>Das Beste kommt zum Schluss: Mit miracl persönlich beraten</h2>
                <p>
                    Vergiss nicht: Solltest du zu den Themen Tilgung und Tilgungsplan noch Fragen haben oder bei der
                    Planung deiner Finanzierung Unterstützung benötigen: Melde dich jederzeit bei unseren{" "}
                    <a href={extendUrlWithSourceVersion(urls.survey)} target="_blank" rel="noreferrer noopener">
                        Berater*innen
                    </a>
                    . In einem persönlichen online-Gespräch, das du bequem von zuhause aus führen kannst, finden wir
                    gemeinsam eine Finanzierung, mit der du rundum zufrieden bist. Vereinbare noch heute einen Termin
                    und mache deinen Haustraum zum Traumhaus!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"tilgung"}></BreadcrumbList>
            <ArticleStructuredData page={"tilgung"} heading={"Tilgung und Tilgungsplan"} />
        </Layout>
    );
};

export default Tilgung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.tilgung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
